import styled from "styled-components";

export const Container = styled.div`
  text-transform: none;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000;
  color: white;
  text-align: center;
  padding: 10px;

  img {
    max-width: 150px;
  }

  h1 {
    font-size: 1.5rem;
    margin-top: 10px;
    font-weight: 500;
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 1.5rem;
    }
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  button {
    margin: 20px 0;
    width: 20%;
  }

  @media screen and (max-width: 767px) {
    button {
      width: 100%;
    }
  }

`;

export const Title = styled.h1`
  font-size: 2.2rem;
  margin-bottom: 20px;
  color: #333;
`;

export const Description = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
`;

export const SectionSobre = styled.section`
  padding: 40px 20px;
  text-align: center;

  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }
`;

export const Column = styled.div`
  display: flex;
  max-width: 300px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const IconWrapper = styled.div`
  font-size: 2rem;
  color: #0077b6;
  margin-bottom: 15px;
  height: 10%;
`;

export const ColumnTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
  height: 20%;
`;

export const ColumnDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  text-align: justify;
  height: 70%;
`;
export const WhatsappDiv = styled.a`
  display: flex;
  justify-content: center;
  width:  '90%';

  img {
    width: 80%;
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
      display: none;
  }
`;
export const FormContainer = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* background-color: cyan; */

  div {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
  }
`

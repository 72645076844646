import React from 'react';
import { Container } from './styles';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeHolder: string;
  animated?: boolean;
}

const FormInput = React.forwardRef<HTMLInputElement, IProps>(
  ({ placeHolder, animated, ...rest}: IProps, ref) => {
  return (
    <Container $animated={animated}>
      <input {...rest} placeholder=' ' ref={ref} />
      <span>{placeHolder}</span>
    </Container>
  );
});

export default FormInput;

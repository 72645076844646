import React, { useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Container } from './styles';

import Context, { IContext } from '../../context/Context';
import tifirelogo from '../../assets/images/tifire.ico';


export default function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { caminho } = useParams();

  const { usuario, empresa }: IContext = useContext(Context);

  function handleNavigate() {
    if (pathname === `${caminho}/login`) return;

    if (usuario) {
      navigate(`/${caminho}/menu`);
      return;
    }

    navigate(`/${caminho}/login`);
  }

  return (
    <Container>
      <div>
        <span onClick={handleNavigate}>
          <img
            src={empresa?.cod === 1 ?
              'https://imagizer.imageshack.com/img922/545/PK5ZMv.jpg' :
              tifirelogo
            }
            alt='logo'
          />
          <p>{empresa?.raz}</p>
        </span>
        {usuario?.usu ? <b>{`${usuario.tip}: ${usuario?.usu}`}</b> : null}
      </div>
      <hr />
    </Container>
  );
}

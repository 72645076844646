import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Container } from './styles';

import TabelaReservas from '../../components/TabelaReservas';
import Button from '../../components/Button';
import Agenda from '../../components/Agenda';
import CadastroGenerico, { ICampos, IFormatoCampos } from '../../components/CadastroGenerico';
import NovaReserva from '../../components/NovaReserva';

import { formatCurrency } from '../../utils/formatCurrency';
import Context, { IContext } from '../../context/Context';

export default function Usuario() {
  const navigate = useNavigate();
  const { caminho } = useParams();
  const { metodo, form, slide } = useParams();

  const { usuario }: IContext = useContext(Context);

  function getCadastroProps(entidade: string, endpoint: string, campos: ICampos[], formatoCampos?: IFormatoCampos) {
    return {
      entidade,
      endpoint,
      campos,
      formatoCampos
    }
  }

  const date = new Date();
  const localDate = new Date(date.getTime());
  const fuso = -3;
  localDate.setHours(localDate.getHours() + fuso);

  switch (metodo) {
    case 'reservas':
      return (
        <Container>
          <TabelaReservas datres={localDate.toISOString().substring(0, 10)} />
        </Container>
      );
    case 'minhasReservas':
      return (
        <Container>
          {usuario?.cod && <TabelaReservas codusu={usuario.cod} />}
        </Container>
      );
    case 'agenda':
      return (
        <Container>
          <Agenda />
        </Container>
      );
    case 'cadastro':
      switch (form) {
        case 'novaReserva':
          return (
            <Container>
              <NovaReserva slide={slide || ''} />
            </Container>
          );
        case 'espaco':
          return (
            <Container>
              <CadastroGenerico
                {...getCadastroProps(
                  'Espaço',
                  '/espacos',
                  [
                    { campo: 'esp', label: 'Espaço', type: 'text' },
                    // { campo: 'des', label: 'Descricão', type: 'text' },
                    { campo: 'obs', label: 'Observação', type: 'text' },
                    { campo: 'det', label: 'Detalhes', type: 'textarea' },
                    { campo: 'cod', label: 'Tipo de Espaço', type: 'select', options: [] },
                    { campo: 'ativo', label: 'Ativo', type: 'checkbox' }
                  ],
                  {}
                )}
              />
            </Container>
          );
        case 'fotoEspaco':
          return (
            <Container>
              <CadastroGenerico
                {...getCadastroProps(
                  'Foto do Espaço',
                  '/fotesp',
                  [
                    { campo: 'linfot', label: 'Foto', type: 'file' },
                    { campo: 'cod', label: 'Espaço', type: 'select', options: [] },
                  ],
                  {}
                )}
              />
            </Container>
          );
        case 'tipoEspaco':
          return (
            <Container>
              <CadastroGenerico
                {...getCadastroProps(
                  'Tipo de Espaço',
                  '/tiposDeEspaco',
                  [
                    { campo: 'tipesp', label: 'Tipo de Espaço', type: 'text' },
                    { campo: 'det', label: 'Detalhes', type: 'textarea' },
                    { campo: 'valseg', label: 'Valor Segunda', type: 'number' },
                    { campo: 'valter', label: 'Valor Terça', type: 'number' },
                    { campo: 'valqua', label: 'Valor Quarta', type: 'number' },
                    { campo: 'valqui', label: 'Valor Quinta', type: 'number' },
                    { campo: 'valsex', label: 'Valor Sexta', type: 'number' },
                    { campo: 'valsab', label: 'Valor Sábado', type: 'number' },
                    { campo: 'valdom', label: 'Valor Domingo', type: 'number' },
                    { campo: 'limcon', label: 'Lim. Convidados', type: 'number' },
                    { campo: 'prares', label: 'Disponibilidade', type: 'number' },
                    { campo: 'limconadi', label: 'Lim. Con. Adicionais', type: 'number' },
                    { campo: 'valconadi', label: 'Valor Con. Adicional', type: 'number' }
                  ],
                  {
                    valseg: formatCurrency,
                    valter: formatCurrency,
                    valqua: formatCurrency,
                    valqui: formatCurrency,
                    valsex: formatCurrency,
                    valsab: formatCurrency,
                    valdom: formatCurrency
                  }
                )}
              />
            </Container>
          );
        case 'valoresEspeciais':
          return (
            <Container>
              <CadastroGenerico
                {...getCadastroProps(
                  'Datas Especiais',
                  '/datasEspeciais',
                  [
                    { campo: 'cod', label: 'Tipo de Espaço', type: 'select', options: [] },
                    { campo: 'dat', label: 'Data', type: 'date' },
                    { campo: 'val', label: 'Valor', type: 'text' },
                  ],
                  {
                    dat: (value: string) => value.substring(0, 10),
                    val: formatCurrency
                  }
                )}
              />
            </Container>
          );
        default:
          return (
            <Container>
              <h1>Página Não Encontrada</h1>
              <Button
                texto='Voltar Para o Menu'
                type='button'
                onClick={() => navigate(`/${caminho}/menu`)}
                style={{ width: '10%' }}
              />
            </Container>
          );
      }

    default:
      return (
        <Container>
          <h1>Página Não Encontrada</h1>
          <Button
            texto='Voltar Para o Menu'
            type='button'
            onClick={() => navigate(`/${caminho}/menu`)}
            style={{ width: '10%' }}
          />
        </Container>
      );
  }
}

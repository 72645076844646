import React, { useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import ReactModal from 'react-modal';

import { Table, TableContainer, TableWrapper, Td, Th, Tr } from './styles';

import Button from '../Button';

import { IReserva } from '../../types/Reserva';
import api from '../../services/api';

import Context, { IContext } from '../../context/Context';
import { destratarData, tratarData } from '../../utils/tratarData';
import useWindowDimensions from '../../utils/WindowDimensions';

import FormInput from '../FormInput';
import ModalReserva from '../ModalReserva';

interface ITabelaReservas {
  codusu?: number;
  datres?: string;
}

export default function TabelaReservas({ codusu, datres }: ITabelaReservas) {
  const navigate = useNavigate();
  const { caminho } = useParams();
  const { setIsLoadingOverlay }: IContext = useContext(Context);
  const { width } = useWindowDimensions();
  const isMobile = width <= 767;

  const [reservas, setReservas] = useState<IReserva[]>([]);
  const [reservaEditando, setReservaEditando] = useState<IReserva | undefined>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  async function getReservas() {
    try {
      setIsLoadingOverlay(true);
      const response = await api.get('/reservas', {
        params: {
          datres: datres,
          codusu: codusu,
          sta: 'PAGO',
          ordem: 'DESC'
        }
      });

      if (response.status === 200) {
        setReservas(response.data);
      }
    } catch (error: any) {
      if (error.response?.data) return toast.error(error.response.data?.erro);
      toast.error('Erro ao buscar reservas');
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  useEffect(() => {
    getReservas();
  }, []);

  return (
    <TableContainer>
      <ModalReserva reservaEditando={reservaEditando} modalVisible={modalVisible} setModalVisible={setModalVisible} />
      <h1>
        {datres ? `Reservas do Dia` : 'Minhas Reservas'}
      </h1>
      <br />
      <TableWrapper>
        <Table>
          <thead>
            <Tr>
              <Th>Data Reservada</Th>
              <Th>Tipo</Th>
              <Th>Espaço</Th>
              {!codusu && <Th>Responsável</Th>}
              <Th>Ações</Th>
            </Tr>
          </thead>
          <tbody>
            {reservas.map((reserva, index) => (
              <Tr key={index}>
                <Td>{new Date(destratarData(reserva.datres)).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}</Td>
                <Td>{reserva.espaco.tipesp.tipesp}</Td>
                <Td>{reserva.espaco.esp}</Td>
                {!codusu && <Td>{reserva.usuario.usu}</Td>}
                <Td style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '10px' }}>
                  <Button
                    texto='Convidados'
                    type='button'
                    style={{ width: '100%' }}
                    onClick={() => navigate(`/${caminho}/reservaDetalhes/${reserva.cod}`)}
                  />
                  <Button
                    texto={codusu === reserva.usuario.codusu ? 'Conferência' : 'Termos de Uso'}
                    type='button'
                    style={{ width: '100%' }}
                    onClick={() => {
                      setReservaEditando(reserva);
                      setModalVisible(true);
                    }}
                    disabled={codusu !== reserva.usuario.codusu && Boolean(reserva?.aceent)}
                  />
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </TableContainer>
  );
}

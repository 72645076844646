import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactModal from 'react-modal';

import { Container, SelectWrapper, Select, Label, Option, SelectContainer, InputsContainer } from './styles';

import Button from '../Button';
import AgendaReserva from '../AgendaReserva';
import FormInput from '../FormInput';

import api from '../../services/api';
import { ITipEsp } from '../../types/TipEsp';
import { IEspaco } from '../../types/Espaco';
import Context, { IContext } from '../../context/Context';
import { cnpjMask, cpfMask } from '../../utils/validaCpfCnpj';
import { IUsuario } from '../../types/Usuario';
import useWindowDimensions from '../../utils/WindowDimensions';
import { IFotEsp } from '../../types/FotEsp';

interface IFormData {
  cgc: string;
  obs: string;
}

export default function NovaReserva({ slide }: { slide: string }) {
  const navigate = useNavigate();
  const { caminho } = useParams();
  const { usuario, setIsLoadingOverlay }: IContext = useContext(Context);

  const [tiposDeEspaco, setTiposDeEspaco] = useState<ITipEsp[]>([]);
  const [fotosDoEspaco, setFotosDoEspaco] = useState<IFotEsp[]>([]);
  const [tipoDeEspacoSelecionado, setTipoDeEspacoSelecionado] = useState<ITipEsp | undefined>();
  const [espacos, setEspacos] = useState<IEspaco[]>([]);
  const [espacoSelecionado, setEspacoSelecionado] = useState<IEspaco | undefined>();
  const [indexCarousel, setIndexCarousel] = useState<number>(0);
  const [formData, setFormData] = useState<IFormData>({
    cgc: '',
    obs: '',
  });
  const [usuarioReserva, setUsuarioReserva] = useState<IUsuario | undefined>();
  const [modalVisible, setModalVisible] = useState(false);

  async function getTiposDeEspaco() {
    try {
      const response = await api.get('/tiposDeEspaco');

      if (response.status === 200) {
        setTiposDeEspaco(response.data);
      }
    } catch (error: any) {
      toast.error('Erro ao buscar Tipos de Espaço');
    }
  }

  async function getEspacos(codTipEsp: number) {
    try {
      setEspacoSelecionado(undefined);

      const response = await api.get('/espacos', {
        params: {
          codtipesp: codTipEsp,
          ativo: true
        }
      });

      if (response.status === 200) {
        setEspacos(response.data);
      }
    } catch (error) {
      toast.error('Erro ao buscar Espaços');
    }
  }

  async function getFotosDoEspaço(codesp?: string | number) {
    try {
      if (!codesp) return;

      setIsLoadingOverlay(true);

      const response = await api.get('/fotesp', {
        params: {
          codesp
        }
      });

      if (response.status === 200) {
        setFotosDoEspaco(response.data);
      }
    } catch (error: any) {
      toast.error('Erro ao buscar Tipos de Espaço');
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 200);
    }
  }

  async function getUsuario(cgc: string) {
    try {
      setIsLoadingOverlay(true);
      const response = await api.get('/usuarios', {
        params: {
          cgc
        }
      });

      if (response.status === 200 && response.data.length > 0) {
        setUsuarioReserva(response.data[0]);
        return;
      }

      setUsuarioReserva(undefined);
      toast.warning('Usuário não encontrado. Verifique o Cpf e tente novamente.');
    } catch (error) {
      toast.error('Falha ao buscar usuário por cpf');
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 200);
    }
  }

  function Modal({ modalVisible, setModalVisible }: any) {
    const { width } = useWindowDimensions();
    const isMobile = width <= 767;

    return (
      <ReactModal
        isOpen={modalVisible}
        appElement={document.getElementById('root') as HTMLElement}
        contentLabel='Minimal Modal Example'
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setModalVisible(false)}
        style={{
          overlay: {
            opacity: 1,
            backdropFilter: 'blur(2px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 99
          },
          content: {
            display: 'flex',
            flexDirection: 'column',
            height: '70%',
            width: isMobile ? '80%' : '40%',
            margin: 'auto',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          },
        }}
      >
        <div style={{ display: 'flex', width: '100%', height: '100%', flexDirection: 'column' }}>
          <b style={{ textAlign: 'center', marginBottom: 10, fontSize: '1.5rem' }}>{espacoSelecionado?.esp}</b>
          <Carousel
            showArrows={true}
            showStatus={false}
            showThumbs={false}
            swipeable={true}
            emulateTouch={false}
            infiniteLoop={false}
            showIndicators={true}
            autoPlay={false}
            className='carousel'
          >
            {fotosDoEspaco.map((fotEsp) => (
              <div key={fotEsp.cod}>
                <img src={fotEsp.linfot} alt={espacoSelecionado?.esp} style={{ width: '100%', maxHeight: 200, objectFit: 'contain' }} />
              </div>
            ))}
          </Carousel>
          <p dangerouslySetInnerHTML={{ __html: espacoSelecionado?.det || '' }} style={{ marginLeft: 10, marginTop: 10 }} />
        </div>
      </ReactModal>
    )
  }

  useEffect(() => {
    getTiposDeEspaco();
  }, []);

  useEffect(() => {
    if (slide === 'agenda') return setIndexCarousel(1);

    setIndexCarousel(0);
  }, [slide]);

  useEffect(() => {
    if (tipoDeEspacoSelecionado?.cod) {
      getEspacos(tipoDeEspacoSelecionado.cod);
    }
  }, [tipoDeEspacoSelecionado]);

  useEffect(() => {
    getFotosDoEspaço(espacoSelecionado?.cod);
  }, [espacoSelecionado]);

  return (
    <Container>
      <Modal modalVisible={modalVisible} setModalVisible={setModalVisible} />
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        swipeable={false}
        emulateTouch={false}
        infiniteLoop={false}
        showIndicators={false}
        autoPlay={false}
        selectedItem={indexCarousel}
        onChange={setIndexCarousel}
        className='carousel'
      >
        <SelectContainer>
          <h1>Nova Reserva</h1>
          <SelectWrapper>
            <Label>Escolha o Tipo de Espaço para Reserva:</Label>
            <Select
              onChange={e => setTipoDeEspacoSelecionado(tiposDeEspaco.find(tipoDeEspaco => tipoDeEspaco.cod === +e.target.value))}
              value={tipoDeEspacoSelecionado?.cod || ''}
            >
              <Option value='' disabled>Selecione o Tipo de Espaço</Option>
              {tiposDeEspaco.map((tipoDeEspaco: ITipEsp, index) => (
                <Option key={index} value={tipoDeEspaco.cod}>{tipoDeEspaco.tipesp}</Option>
              ))}
            </Select>
          </SelectWrapper>

          {tipoDeEspacoSelecionado && (
            <SelectWrapper>
              <Label>Escolha o Espaço para a Reserva:</Label>
              <Select
                onChange={e => setEspacoSelecionado(espacos.find(espaco => espaco.cod === +e.target.value))}
                value={espacoSelecionado?.cod || ''}
              >
                <Option value='' disabled>Selecione o Espaço</Option>
                {espacos.map((espaco: IEspaco, index) => (
                  <Option key={index} value={espaco.cod}>{espaco.esp}</Option>
                ))}
              </Select>
              {(espacoSelecionado && espacoSelecionado.obs) && (
                <p>Obs: {espacoSelecionado.obs}</p>
              )}
            </SelectWrapper>
          )}
          {espacoSelecionado && (
            <Button
              texto='Mais Informações'
              onClick={() => setModalVisible(true)}
              style={{ width: '90%', marginTop: '10px', backgroundColor: '#115fc2' }}
            />
          )}

          {((usuario?.tip === 'ADMINISTRADOR' || usuario?.tip === 'ASSISTENTE') && espacoSelecionado) &&
            <InputsContainer>
              <Label>Reservar para Outro Usuário: {usuarioReserva?.usu || ''}</Label>
              <div style={{ width: '100%' }}>
                <FormInput
                  placeHolder='CPF/CNPJ do Titular'
                  type='tel'
                  required
                  maxLength={18}
                  onChange={e =>
                    setFormData({
                      ...formData, cgc: e.target.value.length <= 14 ?
                        cpfMask(e.target.value) :
                        cnpjMask(e.target.value)
                    })
                  }
                  value={formData.cgc}
                  style={{ marginLeft: -15 }}
                  onBlur={() => {
                    if (formData.cgc && formData.cgc.length >= 14) {
                      getUsuario(formData.cgc);
                      return;
                    }

                    setFormData({ ...formData, cgc: '' });
                    setUsuarioReserva(undefined);
                  }}
                />
                <FormInput
                  placeHolder='Observação'
                  type='text'
                  required
                  onChange={e => setFormData({ ...formData, obs: e.target.value })}
                  value={formData.obs}
                  style={{ marginLeft: -15 }}
                />
              </div>
            </InputsContainer>
          }

          {(espacoSelecionado && (!formData.cgc || (formData.cgc && formData.cgc.length >= 14 && usuarioReserva?.cod))) &&
            <Button
              texto='Consultar Disponibilidade'
              onClick={async () => {
                navigate(`/${caminho}/usuario/cadastro/novaReserva/agenda`);
                setIndexCarousel(1);
              }}
              style={{ width: '90%', marginTop: '10px' }}
              animated
            />}
        </SelectContainer>
        <>{(espacoSelecionado && indexCarousel === 1) &&
          <AgendaReserva espaco={espacoSelecionado} detalhesReserva={usuarioReserva ? { codusu: usuarioReserva.cod, usu: usuarioReserva.usu, obs: formData.obs } : undefined} />
        }</>
      </Carousel>
    </Container>
  );
}

import { createContext } from 'react';
import { IUsuario } from '../types/Usuario';
import { IEmpresa } from '../types/Empresa';
import { IConfig } from '../types/Config';

export interface IContext {
  configs: IConfig[];
  setConfigs: (configs: IConfig[]) => void;
  isLoadingOverlay: boolean;
  setIsLoadingOverlay: (isLoadingOverlay: boolean) => void;
  usuario: IUsuario | undefined;
  setUsuario: (usuario: IUsuario | undefined) => void;
  empresa: IEmpresa | undefined;
  setEmpresa: (empresa: IEmpresa| undefined) => void;
}

const Context = createContext<IContext>({} as IContext);

export default Context;

import React, { useContext, useEffect, useState } from 'react'
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';

import Button from '../Button';
import FormInput from '../FormInput';

import Context, { IContext } from '../../context/Context';
import { IReserva } from '../../types/Reserva';
import useWindowDimensions from '../../utils/WindowDimensions';
import api from '../../services/api';

export default function ModalReserva({ modalVisible, setModalVisible, reservaEditando }: any) {
  const { width } = useWindowDimensions();
  const isMobile = width <= 767;
  const { setIsLoadingOverlay, usuario, empresa }: IContext = useContext(Context);

  const [obsInput, setObsInput] = useState('');

  async function putReserva(reserva: IReserva) {
      try {
        setIsLoadingOverlay(true);

        const payload = {
          codesp: reserva.espaco.cod,
          datres: reserva.datres,
          codusu: reserva.usuario.cod,
          sta: reserva.sta,
          aceent: reserva.aceent,
          acesai: reserva.acesai,
          val: reserva.val,
          obs: reserva.obs,
          checkout: reserva.checkout,
          obster: reserva.obster
        }

        const response = await api.put(`/reservas/${reserva.cod}`, payload);

        if (response.status === 200) {
          toast.success('Aceite confirmado com sucesso.');
          setModalVisible(false);
        }
      } catch (error: any) {
        if (error.response.data) return toast.error(error.response.data.erro);
        toast.error('Erro ao editar reserva.');
      } finally {
        setTimeout(() => {
          setIsLoadingOverlay(false);
        }, 500);
      }
    }

  return (
    <ReactModal
      isOpen={modalVisible}
      appElement={document.getElementById('root') as HTMLElement}
      contentLabel='Minimal Modal Example'
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setModalVisible(false)}
      style={{
        overlay: {
          backgroundColor: '#1D1D1D',
          opacity: 0.95,
          zIndex: 99
        },
        content: {
          display: 'flex',
          flexDirection: 'column',
          height: '90%',
          width: isMobile ? '80%' : '30%',
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'space-between'
        },
      }}
    >
      <b>{usuario?.cod === reservaEditando?.usuario.codusu ? `Conferência - ${reservaEditando?.espaco.esp} - ${reservaEditando?.datres}` : 'Aceite do Termo de Responsabilidade'}</b>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        {reservaEditando?.espaco.tipesp.det &&
          (
            <p dangerouslySetInnerHTML={{ __html: reservaEditando?.espaco.tipesp.det }} />
          )}
      </div>
      {!(usuario?.cod === reservaEditando?.usuario.codusu) && <p style={{ textAlign: 'justify' }}>Eu <b>{reservaEditando?.usuario.usu}</b>, associado(a) da empresa {empresa?.raz || ''}, responsável pela reserva do espaco <b>{reservaEditando?.espaco.esp}</b> no dia <b>{reservaEditando?.datres}</b>. Declaro que retirei a título de empréstimo os utensílios abaixo discriminados, comprometendo-me a devolvê-los em perfeitas condições após o evento.</p>}
      <div style={{ width: '100%', marginLeft: '-1.5%' }}>
        <FormInput
          placeHolder={usuario?.cod === reservaEditando?.usuario.codusu ? 'Perdas e Observação' : 'Digite Aqui Qualquer Observação'}
          type='textarea'
          style={{ width: '100%' }}
          value={obsInput}
          onChange={(e) => setObsInput(e.target.value)}
        />
      </div>
      <p>Ao confirmar, estou de acordo com o
        <a
          href='https://www.afmm.com.br/arquivos/downloads/TERMO-DE-RESPONSABILIDADE.pdf'
          target='_blank'
          rel='noreferrer'
          style={{
            color: 'blue',
            cursor: 'pointer'
          }}
        >
          &nbsp;Termo de Responsabilidade.
        </a>
      </p>
      <Button
        texto={usuario?.cod === reservaEditando?.usuario.codusu ? 'Confirmar Conferência' : 'Estou Ciente'}
        onClick={() => {
          const novaReserva = reservaEditando;

          if (novaReserva) {
            // if (novaReserva.aceent) {
            //   return toast.warning(`Aceite de Saída Já Confirmado às ${novaReserva.aceent}.`);
            // }

            if (usuario?.cod === reservaEditando?.usuario.codusu) {
              novaReserva.acesai = new Date().toISOString();
            }

            novaReserva.aceent = new Date().toISOString();
            novaReserva.obster = obsInput;

            putReserva(novaReserva);
          }
        }}
        style={{ width: '50%' }}
      />
    </ReactModal>
  )
}

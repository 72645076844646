import styled, { keyframes } from 'styled-components';
import { zoomIn } from 'react-animations';

const animationType = keyframes`${zoomIn}`;

export const Container = styled.main`
  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    h1 {
      margin: 1rem 0px;
    }
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SelectWrapper = styled.div`
  animation: 0.5s ${animationType};
  margin-bottom: 1.5rem;
  position: relative;
  margin: 5px;
  width: 100%;
  align-self: center;

  p {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0.3rem;
    color: red;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const InputsContainer = styled.div`
  animation: 0.5s ${animationType};
  width: 90%;
`;

export const Label = styled.label`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

export const Select = styled.select`
  width: 90%;
  padding: 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #f1f1f1;
  color: #333;
  font-size: 14px;
  font-weight: 550;
  transition: 0.3s ease-in-out;
  box-shadow: 0 0 0 5px transparent;
  appearance: none;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 2px #333;
  }
`;

export const Option = styled.option`
  background-color: #ffffff;
  color: #333;
  font-size: 14px;
  padding: 15px;
  font-weight: 500;

  &:hover {
    background-color: #f1f1f1;
  }

  &:checked {
    background-color: #e0e0e0;
  }
`;

import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  BotaoMenu,
  ButtonsContainer,
  Container
} from './styles';

import { menuData } from '../../mocks';
import Context, { IContext } from '../../context/Context';

export default function Menu() {
  const { caminho } = useParams();
  const { usuario }: IContext = useContext(Context);
  const navigate = useNavigate();

  return (
    <Container>
      <ButtonsContainer>
        {menuData.map((item, index) => (
          (item.permissao.includes(usuario?.tip || '') || item.permissao === 'TODOS') &&
          <BotaoMenu
            key={index}
            onClick={() => navigate(`/${caminho}${item.caminho}`)}
          >
            {item.icone}
            <span>
              {item.titulo}
            </span>
          </BotaoMenu>
        ))}
      </ButtonsContainer>
    </Container>
  );
}

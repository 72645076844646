import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useNavigate,
  useLocation,
  useParams
} from 'react-router-dom';
import Context, { IContext } from '../../context/Context';
import { toast } from 'react-toastify';

import { Login } from '../../pages/Login';
import ErrorPage from '../../pages/ErrorPage';
import Menu from '../../pages/Menu';
import Usuario from '../../pages/Usuario';
import ReservaDetalhes from '../../pages/ReservaDetalhes';

import Header from '../Header';
import Cookies from 'js-cookie';
import Home from '../../pages/Home';
import api from '../../services/api';

function LayoutFixo() {
  const { pathname } = useLocation();
  const { caminho } = useParams();
  const { setUsuario, empresa }: IContext = useContext(Context);
  const navigate = useNavigate();

  function getCookies() {
    const usuarioCookie = Cookies.get('usuarioCookie');

    if (!usuarioCookie && pathname !== '/' && !pathname.includes('login')) {
      toast.warning('Necessario realizar o login');

      if (empresa?.cod) {
        navigate(`/${caminho}/login`);
        return;
      }

      navigate('/');
      return;
    }

    if (!usuarioCookie) return;

    setUsuario(JSON.parse(usuarioCookie));
  }

  useEffect(() => {
    getCookies();
  }, [pathname]);

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
}

export default function RouterComponent() {
  const { empresa, setEmpresa, setIsLoadingOverlay }: IContext = useContext(Context);

  async function getDadosEmpresa(identificador: string) {
    try {
      if (!identificador) return;

      setIsLoadingOverlay(true);

      let response;

      if (/\d/.test(identificador)) {
        response = await api.get(`/empresas/${identificador}`);
      } else {
        response = await api.get(`/empresas`, {
          params: {
            caminho: identificador
          }
        });
      }

      if (response.status === 200) {
        if (Array.isArray(response.data)) {
          response.data = response.data[0];
        }

        window.document.title = response.data.raz;

        api.interceptors.request.use(config => {
          const codemp = response.data.cod;
          if (config.headers && codemp) {
            config.headers['codemp'] = codemp;
          }
          return config;
        }, error => {
          return Promise.reject(error);
        });

        setEmpresa(response.data);
      }
    } catch (error: any) {
      if (error.response?.data?.erro) {
        toast.error(error.response.data.erro);
        return;
      }

      toast.error('Erro ao buscar dados da empresa');
    } finally {
      setIsLoadingOverlay(false);
    }
  }

  useEffect(() => {
    getDadosEmpresa(window.location.pathname.split('/')[1]);
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          {empresa && <Route element={<LayoutFixo />}>
            <Route path='/:caminho' element={<Login />} />
            <Route path='/:caminho/login' element={<Login />} />
            <Route path='/:caminho/menu' element={<Menu />} />
            <Route path='/:caminho/usuario/:metodo' element={<Usuario />} />
            <Route path='/:caminho/usuario/:metodo/:form' element={<Usuario />} />
            <Route path='/:caminho/usuario/:metodo/:form/:slide' element={<Usuario />} />
            <Route path='/:caminho/reservaDetalhes/:cod' element={<ReservaDetalhes />} />
          </Route>}
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </Router>
    </>
  );
}

import React, { useContext, useState } from 'react';
import ReactModal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Column, ColumnDescription, ColumnTitle,
  Container, Description, FormContainer, Header,
  IconWrapper, Main, SectionSobre,
  Title,
  WhatsappDiv
} from './styles';

import Copyright from '../../components/Copyright';
import Button from '../../components/Button';
import useWindowDimensions from '../../utils/WindowDimensions';
import FormInput from '../../components/FormInput';

import api from '../../services/api';
import Context, { IContext } from '../../context/Context';
import { cnpjMask, cpfMask, validaCpfCnpj } from '../../utils/validaCpfCnpj';
import WhatsappSVG from '../../assets/images/Whatsapp.svg';

function Modal(
  { modalVisible, setModalVisible }:
    { modalVisible: boolean, setModalVisible: React.Dispatch<React.SetStateAction<boolean>> }
) {
  const { setIsLoadingOverlay, setEmpresa }: IContext = useContext(Context);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const isMobile = width <= 767;
  const [formData, setFormData] = useState({
    raz: '',
    fan: '',
    cgc: '',
    caminho: '',
    usu: '',
    cgcUsu: '',
    sen: ''
  });

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!validaCpfCnpj(formData.cgc)) return toast.error('CNPJ inválido.');
    if (!validaCpfCnpj(formData.cgcUsu)) return toast.error('CPF inválido.');

    try {
      setIsLoadingOverlay(true);

      const payloadEmpresa = {
        cgc: formData.cgc,
        raz: formData.raz,
        fan: formData.fan,
        caminho: formData.caminho
      };

      const responseEmpresa = await api.post('/empresas', payloadEmpresa);

      if (responseEmpresa.status === 201) {
        setEmpresa(responseEmpresa.data);
        const payloadUsuario = {
          usu: formData.usu,
          cgc: formData.cgcUsu,
          sen: formData.sen,
          tip: 'ADMINISTRADOR',
        };

        const responseUsuario = await api.post('/usuarios', payloadUsuario, {
          headers: {
            codemp: responseEmpresa.data.cod
          }
        });

        if (responseUsuario.status === 201) {
          api.interceptors.request.use(config => {
            const codemp = responseEmpresa.data.cod;
            if (config.headers && codemp) {
              config.headers['codemp'] = codemp;
            }
            return config;
          }, error => {
            return Promise.reject(error);
          });

          toast.success('Acesse utilizando CPF e Senha do responsável');
          setModalVisible(false);
          navigate(`/${responseEmpresa.data.cod}/login`);
        }
      }

    } catch (error: any) {
      if (error.response.data) return toast.error(error.response.data.erro);
      toast.error('Falha ao registrar conta.');
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  return (
    <ReactModal
      isOpen={modalVisible}
      appElement={document.getElementById('root') as HTMLElement}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setModalVisible(false)}
      style={{
        overlay: {
          opacity: 1,
          backdropFilter: 'blur(2px)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 99
        },
        content: {
          display: 'flex',
          flexDirection: 'column',
          width: isMobile ? '80%' : '30%',
          maxHeight: '70%',
          margin: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <FormContainer onSubmit={handleSubmit}>
        <h1>Faça seu Cadastro</h1>
        <div>
          <FormInput
            placeHolder='CNPJ'
            value={formData.cgc}
            required
            onChange={e => setFormData({ ...formData, cgc: cnpjMask(e.target.value) })}
            onBlur={e => {
              if (e.target.value.length === 18 && validaCpfCnpj(e.target.value)) return;
              setFormData({ ...formData, cgc: '' })
              toast.error('CNPJ inválido');
            }}
            maxLength={18}
          />
          <FormInput
            placeHolder='Razão Social'
            value={formData.raz}
            required
            onChange={e => setFormData({ ...formData, raz: e.target.value })}
          />
          <FormInput
            placeHolder='Nome Fantasia'
            value={formData.fan}
            required
            onChange={e => setFormData({ ...formData, fan: e.target.value })}
          />
          {/* <FormInput
          placeHolder='Caminho'
          value={formData.caminho}
          onChange={e => setFormData({ ...formData, caminho: e.target.value })}
        /> */}
          <FormInput
            placeHolder='Nome do Responsável'
            value={formData.usu}
            required
            onChange={e => setFormData({ ...formData, usu: e.target.value })}
          />
          <FormInput
            placeHolder='CPF do Responsável'
            value={formData.cgcUsu}
            required
            onChange={e => setFormData({ ...formData, cgcUsu: cpfMask(e.target.value) })}
            onBlur={e => {
              if (e.target.value.length === 14 && validaCpfCnpj(e.target.value)) return;
              setFormData({ ...formData, cgcUsu: '' })
              toast.error('CPF inválido');
            }}
            maxLength={14}
          />
          <FormInput
            placeHolder='Senha'
            value={formData.sen}
            required
            onChange={e => setFormData({ ...formData, sen: e.target.value })}
          />
        </div>
        <Button
          type='submit'
          texto='Confirmar'
          style={{ width: isMobile ? '80%' : '40%' }}
        />
      </FormContainer>
    </ReactModal>
  )
}

export default function Home() {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <Container>
      <Modal modalVisible={modalVisible} setModalVisible={setModalVisible} />
      <Header>
        <img src='https://tifire.com/assets/img/fogo2.png' alt='logo' />
        <h1>Agenda para Clubes e Associações</h1>
      </Header>
      <Main>
        <SectionSobre>
          <Title>O que nosso Sistema oferece?</Title>
          <Description>
            Com nossa Plataforma, sua Gestão de Clubes e Associações se torna mais eficiente.
            Confira algumas vantagens:
          </Description>
          <div>
            <Column>
              <IconWrapper>📅</IconWrapper>
              <ColumnTitle>Agendamento Simplificado</ColumnTitle>
              <ColumnDescription>
                Acesse a disponibilidade de espaços em tempo real e facilite as reservas dos associados.
              </ColumnDescription>
            </Column>
            <Column>
              <IconWrapper>💳</IconWrapper>
              <ColumnTitle>Pagamentos Online</ColumnTitle>
              <ColumnDescription>
                Integre pagamentos de forma segura e automatizada por plataformas de pagamento.
              </ColumnDescription>
            </Column>
            <Column>
              <IconWrapper>👥</IconWrapper>
              <ColumnTitle>Controle de Convidados</ColumnTitle>
              <ColumnDescription>
                Gerencie a lista de convidados com check-in na portaria e mantenha o controle em tempo real.
              </ColumnDescription>
            </Column>
          </div>
        </SectionSobre>
        <Button texto='Comece Agora' onClick={() => setModalVisible(true)} />
       
        <WhatsappDiv
          href={`https://api.whatsapp.com/send?phone=554497430173&text=Olá!! Tenho interesse na agenda para Clubes e Associações`}
          target='_blank'
          rel="noreferrer"
          width='100' >
          <img
           src={WhatsappSVG}
            alt='Fale com a Gente'
            loading='lazy'
          />
        </WhatsappDiv>
      </Main>
      <Copyright />
    </Container>
  );
};

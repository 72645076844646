import React, { useContext } from 'react';
import { Button, Container, GifDiv } from './styles';
import Context, { IContext } from '../../context/Context';

export default function ErrorPage() {
  const { empresa, isLoadingOverlay }: IContext = useContext(Context);

  return (
    <Container>
      {(!empresa && !isLoadingOverlay) ?
        <>
          <h1>404</h1>
          <GifDiv />
          <h2>Parece que você está perdido</h2>
          <span>
            A página que está procurando não está disponível, verique a URL digitada.
          </span>
          <a href='/'>
            <Button>
              Página Inicial
            </Button>
          </a>
        </> : <div className='loader'></div>}
    </Container>
  );
}

import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as MdIcons from 'react-icons/md';
import * as FaIcons from 'react-icons/fa';

export const menuData = [
  {
    titulo: 'Nova Reserva',
    caminho: '/usuario/cadastro/novaReserva',
    icone: <MdIcons.MdAddCircle />,
    permissao: 'TODOS',
  },
  {
    titulo: 'Minhas Reservas',
    caminho: '/usuario/minhasReservas',
    icone: <AiIcons.AiOutlineCalendar />,
    permissao: 'TODOS',
  },
  {
    titulo: 'Tipos de Espaço',
    caminho: '/usuario/cadastro/tipoEspaco',
    icone: <FaIcons.FaTags />,
    permissao: 'ADMINISTRADOR',
  },
  {
    titulo: 'Espaços',
    caminho: '/usuario/cadastro/espaco',
    icone: <FaIcons.FaMapMarkerAlt />,
    permissao: 'ADMINISTRADOR',
  },
  {
    titulo: 'Fotos dos Espaços',
    caminho: '/usuario/cadastro/fotoEspaco',
    icone: <FaIcons.FaImages />,
    permissao: 'ADMINISTRADOR',
  },
  {
    titulo: 'Valores Especiais',
    caminho: '/usuario/cadastro/valoresEspeciais',
    icone: <MdIcons.MdAttachMoney />,
    permissao: 'ADMINISTRADOR',
  },
  {
    titulo: 'Agenda',
    caminho: '/usuario/agenda',
    icone: <MdIcons.MdCalendarToday />,
    permissao: 'ADMINISTRADOR | ASSISTENTE',
  },
  {
    titulo: 'Portaria',
    caminho: '/usuario/reservas',
    icone: <FaIcons.FaCalendarCheck />,
    permissao: 'ADMINISTRADOR | ASSISTENTE | CONTROLE',
  },
];
